import GetId from "OrganizationalEngineering/utils/getId";

const companyRoutes = [
  "feed_company",
  "friends_company",
  "p_company",
  "publication_company",
  "article_company",
  "notifications_company",
];

export default function optionsGenerate() {
  const companyId = GetId();
  const companyByUser = companyRoutes.includes(window.location.pathname.split("/")[1]);

  const options = companyByUser
    ? {
        headers: {
          "Content-Type": "multipart/form-data",
          companyId: companyId as any,
        },
      }
    : {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

  const optionsJson = companyByUser
    ? {
        headers: {
          "Content-Type": "application/json",
          companyId: companyId as any,
        },
      }
    : {
        headers: {
          "Content-Type": "application/json",
        },
      };

  return { options, optionsJson, companyByUser, companyId };
}
