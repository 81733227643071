import React, { createContext, useContext, useState } from "react";
import { AxiosResponse } from "axios";
import api from "services/api";
import { links } from "safira-app/config/links";
import { Paginate } from "interfaces/Paginate";

interface IGroupType {
  groupData: any;
  groupInvites: any;
  createGroup: (data: FormData) => Promise<AxiosResponse<any>>;
  getGroupData: (groupId: string) => Promise<AxiosResponse<any>>;
  updateGroupFunction: () => void;
  groupAdmins: any;
  getGroupAdmins: (groupId: string) => Promise<AxiosResponse<any>>;
  submitEditModal: (groupId: string, formData: any) => void;
  groupFeed: any;
  getGroupFeed: (groupId: string, attachs?: boolean) => Promise<any>;
  updateGroupFeed: () => void;
  deletePublication: (pubId: string) => Promise<void>;
  leaveGroup: (groupId: string) => Promise<void>;
  alterMemberPermission: (groupId: string, memberId: string, memberType: string) => void;
  removeMember: (groupId: string, memberId: string) => void;
  joinTheGroup: (groupId: string) => Promise<AxiosResponse<any>>;
  inviteMembers: (groupId: string, members: Array<string>, readMembers: boolean) => Promise<AxiosResponse<any>>;
  manageInvite: (groupId: string, status: string) => Promise<AxiosResponse<any>>;
  manageRequest: (groupId: string, status: string, profileId: string) => void;
  listInvites: (groupId: string) => Promise<any>;
  updateGroupInvites: () => void;
  getMembersEfficiency: (projectId: string) => Promise<AxiosResponse<any>>;
  getProjectEfficiency: (projectId: string) => Promise<AxiosResponse<any>>;
  membersEfficiency: any;
  projectEfficiency: any;
  setGroupCover: any;
  groupCover: any;
  getAllGroups: (page?: number, perPage?: number, search?: string) => Promise<AxiosResponse<Paginate<any>, any>>;
}

enum GroupType {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  SECRET = "SECRET",
}

enum MemberType {
  READER = "READER",
  REGULAR = "REGULAR",
  ADMIN = "ADMIN",
}

interface GroupInterface {
  id: string;
  owner_id: string;
  owner_type: "COMPANY" | "PERSON";
  name: string;
  cover: string | null;
  about: string;
  type: GroupType;
  default_member_type: MemberType;
  created_at: string;
  updated_at: string;
  owner: {
    profile_id: string;
    name: string;
    username: string;
    avatar: string;
    type: "COMPANY" | "PERSON";
    share_my_publications: boolean;
  };
  members: Array<any>;
  isAdmin: boolean;
  isOwner: boolean;
  isMember: boolean;
  totalMembers: number;
  memberType: "OWNER" | "ADMIN" | "REGULAR" | "READER";
  totalPublications: number;
  totalPublicationsToday: number;
  totalAttachments: number;
  totalMembersLastMonth: number;
}

interface AdminInterface {
  meta: object;
  data: Array<any>;
}

interface FeedInterface {
  meta: any;
  data: Array<any>;
}

interface MembersEfficiency {
  id: string;
  creator_profile_id: any;
  is_administrator: boolean;
  member_efficiency: {
    percent_activities_completed: number;
    percent_activities_late: number;
    percent_activities_open: number;
    efficiency: number;
  };
  profile: {
    id: string;
    name: string;
    avatar: string;
    user_id: string;
  };
}

let GroupContext = createContext({} as IGroupType);

export const GroupProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // Estados
  const [groupData, setGroupData] = useState<GroupInterface>();
  const [groupAdmins, setGroupAdmins] = useState<AdminInterface>();
  const [groupFeed, setGroupFeed] = useState<FeedInterface>();
  const [groupInvites, setGroupInvites] = useState<Array<any>>();
  const [membersEfficiency, setMembersEfficiency] = useState<Array<MembersEfficiency>>([]);
  const [projectEfficiency, setProjectEfficiency] = useState<any>();
  const [groupCover, setGroupCover] = useState<any>();

  async function createGroup(data: FormData) {
    const response = await api.post(`${links.api.social}/groups`, data);
    return response;
  }

  // informacoes do grupo
  async function getGroupData(groupId: string) {
    const response = await api.get(`${links.api.social}/groups/${groupId}`);
    if (response.data) {
      setGroupData(response.data);
      setGroupCover(response.data.cover);
    }
    return response;
  }

  function updateGroupFunction() {
    if (groupData?.id) {
      getGroupData(groupData.id);
    }
  }

  // pegar administradores do grupo
  async function getGroupAdmins(groupId: string) {
    const response = await api.get(`${links.api.social}/groups/${groupId}/members/administrators`);
    if (response.data) {
      setGroupAdmins(response.data);
    }
    return response;
  }

  // submit modal de editar o grupo
  async function submitEditModal(groupId: string, formData: any) {
    await api.put(`${links.api.social}/groups/${groupId}`, formData);
    updateGroupFunction();
  }

  // alterar permissão de membro
  async function alterMemberPermission(groupId: string, memberId: string, memberType: string) {
    await api.put(`${links.api.social}/groups/${groupId}/members/type`, { member: memberId, type: memberType });
    updateGroupFunction();
  }

  // remover membro
  async function removeMember(groupId: string, memberId: string) {
    await api.put(`${links.api.social}/groups/${groupId}/members/remove`, { members: [`${memberId}`] });
    updateGroupFunction();
  }

  // solicitar entrada
  async function joinTheGroup(groupId: string) {
    const response = await api.post(`${links.api.social}/groups/${groupId}/members/request`);
    updateGroupFunction();
    return response;
  }

  // convidar membro
  async function inviteMembers(groupId: string, members: Array<string>, readerMembers: boolean) {
    let response = await api.post(`${links.api.social}/groups/${groupId}/members/pending`, {
      type: readerMembers ? "READER" : "REGULAR",
      members: members,
    });
    return response;
  }

  async function manageInvite(groupId: string, status: string) {
    let response = await api.patch(`${links.api.social}/groups/${groupId}/members/invite`, { status });
    return response;
  }

  // listar invites
  async function listInvites(groupId: string | undefined) {
    let response = await api.get(`${links.api.social}/groups/${groupId}/members/solicitants`);
    if (response.data) {
      setGroupInvites(response.data.data);
    }
    return response;
  }

  async function updateGroupInvites() {
    if (groupInvites?.length) {
      listInvites(groupData?.id);
    }
  }

  // manipular requests
  async function manageRequest(groupId: string, status: string, profileId: string) {
    await api.patch(`${links.api.social}/groups/${groupId}/members/request`, { status: status, profile_id: profileId });
    updateGroupFunction();
    updateGroupInvites();
  }

  // eficiências de membros e projeto
  async function getMembersEfficiency(projectId: string) {
    const response = await api.get(`${links.api.project}/projects/${projectId}/efficiency/members`);
    if (response.data) {
      setMembersEfficiency(response.data);
    }
    return response;
  }

  async function getProjectEfficiency(projectId: string) {
    const response = await api.get(`${links.api.project}/projects/${projectId}/efficiency`);
    if (response.data) {
      setProjectEfficiency(response.data);
    }
    return response;
  }

  // publicacoes do grupo (files e etc)
  async function getGroupFeed(groupId: string, attachs?: boolean) {
    let response = await api.get(`${links.api.social}/groups/${groupId}/feed?attachs=${attachs}`);
    if (response.data) {
      setGroupFeed(response.data);
    }
    return response;
  }

  async function updateGroupFeed() {
    if (groupFeed?.data) {
      if (groupData?.id) {
        getGroupFeed(groupData?.id, true);
      }
    }
  }

  async function deletePublication(pubId: string) {
    await api.delete(`${links.api.social}/publications/${pubId}`);
    updateGroupFunction();
  }

  async function leaveGroup(groupId: string) {
    await api.patch(`${links.api.social}/groups/${groupId}/members/leave`);
  }

  async function getAllGroups(page = 1, perPage = 15, search?: string) {
    return await api.get<Paginate<any>>(`${links.api.social}/groups`, {
      params: { page, perPage, search },
    });
  }

  return (
    <GroupContext.Provider
      value={{
        groupData,
        groupInvites,
        createGroup,
        getGroupData,
        updateGroupFunction,
        groupAdmins,
        getGroupAdmins,
        submitEditModal,
        groupFeed,
        getGroupFeed,
        updateGroupFeed,
        deletePublication,
        leaveGroup,
        alterMemberPermission,
        removeMember,
        joinTheGroup,
        inviteMembers,
        manageInvite,
        manageRequest,
        listInvites,
        updateGroupInvites,
        getMembersEfficiency,
        getProjectEfficiency,
        membersEfficiency,
        projectEfficiency,
        setGroupCover,
        groupCover,
        getAllGroups,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export function useGroupContext() {
  return useContext(GroupContext);
}
