import React, { useState, createContext, useContext, useEffect } from "react";
import { PublicationProps, PublicationData } from "interfaces/Publications";

interface IListPublicationsContext {
  publications: PublicationProps[] | null | undefined;
  setPublications: React.Dispatch<React.SetStateAction<PublicationProps[] | null | undefined>>;
  pinnedPublications: PublicationProps[] | null | undefined;
  setPinnedPublications: React.Dispatch<React.SetStateAction<PublicationProps[] | null | undefined>>;
  length: number;
  setTotalPublications: React.Dispatch<React.SetStateAction<number>>;
  totalPublications: number;
  loadingNewPublications: boolean;
  setLoadingNewPublications: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ListPublicationsContext = createContext({} as IListPublicationsContext);

export const ListPublicationsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [publications, setPublications] = useState<PublicationProps[] | null | undefined>();
  const [pinnedPublications, setPinnedPublications] = useState<PublicationProps[] | null | undefined>();
  const [length, setLength] = useState(0);
  const [totalPublications, setTotalPublications] = useState(0);
  const [loadingNewPublications, setLoadingNewPublications] = useState(false);

  useEffect(() => {
    if (publications) setLength(publications.length);
  }, [publications]);

  const context = {
    publications,
    setPublications,
    length,
    setTotalPublications,
    totalPublications,
    loadingNewPublications,
    setLoadingNewPublications,
    pinnedPublications,
    setPinnedPublications,
  };

  return <ListPublicationsContext.Provider value={context}>{children}</ListPublicationsContext.Provider>;
};

export function useListPublications() {
  return useContext(ListPublicationsContext);
}
