import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useAuth } from "safira-app/hooks/useAuth";
import { About, Address, ProfileData, Experience } from "interfaces/ProfileGlobal";
import api from "services/api";
import { links } from "safira-app/config/links";
import { MeProps } from "interfaces/Me";
import { PublicationProps } from "interfaces/Publications";
import { SuggestionData } from "interfaces/Suggestion";
import Cookies from "js-cookie";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface propsSocialContext {
  children: ReactNode;
}

export interface SocialContextProps {
  me: MeProps;
  setMe: SetState<MeProps>;
  about: About;
  setAbout: SetState<About>;
  profiles: ProfileData;
  setProfiles: SetState<ProfileData>;
  getProfileData: (username: string) => Promise<ProfileData>;
  updateProfile: () => void;
  updateMe: () => void;
  publication: PublicationProps[];
  setPublication: SetState<PublicationProps[]>;
  recommendations: any;
  setRecommendations: Function;
  suggestions: SuggestionData;
  setSuggestions: SetState<SuggestionData>;
  experiences: Experience[];
  setExperiences: SetState<Experience[]>;
  address: Address[];
  setAddress: SetState<Address[]>;
  formations: any;
  setFormations: Function;
  connections: any;
  setConnections: Function;
  myPermissions: any;
  setMyPermissions: Function;
  modulePermissions: any;
  setModulePermissions: Function;
  companySelected: any;
  setCompanySelected: Function;
}

const baseUrlCore = links.api.core;

const SocialContext = createContext<SocialContextProps>({
  me: {} as MeProps,
  setMe: () => {},
  about: {} as About,
  setAbout: () => {},
  profiles: {} as ProfileData,
  setProfiles: () => {},
  getProfileData: (() => {}) as any,
  updateProfile: (() => {}) as any,
  updateMe: () => {},
  publication: [],
  setPublication: () => [],
  recommendations: {
    received: [],
    sent: [],
  },
  setRecommendations: () => {},
  suggestions: {} as SuggestionData,
  setSuggestions: () => {},
  experiences: [],
  setExperiences: () => [],
  address: [] as Address[],
  setAddress: (() => {}) as any,
  formations: [],
  setFormations: () => [],
  connections: [],
  setConnections: () => [],
  myPermissions: [],
  setMyPermissions: () => [],
  modulePermissions: [],
  setModulePermissions: () => [],
  companySelected: "",
  setCompanySelected: () => "",
});

async function getPermissions(isPerson: boolean, userInfo: any, cookieCompanyId: any) {
  var id;
  if (!isPerson) {
    return [];
  }
  if (userInfo.companies?.length === 0) {
    return [];
  }
  if (cookieCompanyId && userInfo?.companies.map((pos: any) => pos.id).includes(cookieCompanyId)) {
    id = cookieCompanyId;
  } else {
    id = userInfo?.companies[0].id;
  }
  const options = {
    headers: {
      companyid: id,
    },
  };
  const response = await api.get(`${baseUrlCore}/api/v1/check-my-permissions`, options as any);

  const arr = response.data.permissions;
  const auxMyPermissions = arr.map((item: any) => item.slug);
  return auxMyPermissions;
}

export default function SocialProvider({ children }: propsSocialContext) {
  const { user } = useAuth();
  const cookieCompanyId = Cookies.get("companySelected");
  const userInfo = user as any;
  const isPerson = userInfo?.config?.default_profile_type === "person";

  const [about, setAbout] = useState({} as About);
  const [profiles, setProfiles] = useState<ProfileData>({} as ProfileData);
  const [publication, setPublication] = useState<any>([]);
  const [recommendations, setRecommendations] = useState<any>({
    received: [],
    sent: [],
  });

  const [suggestions, setSuggestions] = useState<any>([]);
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [formations, setFormations] = useState<any>([]);
  const [connections, setConnections] = useState<any>([]);
  const [myPermissions, setMyPermissions] = useState<any>("loading");
  const [modulePermissions, setModulePermissions] = useState<any>([]);
  const [companySelected, setCompanySelected] = useState<any>();
  const [address, setAddress] = useState([] as Address[]);
  const [me, setMe] = useState({} as MeProps);

  function updateProfile() {
    getProfileData(profiles.profile.username || "").then(response => {
      setProfiles(response);
    });
  }

  function updateMe() {
    getMe().then(response => {
      setMe(response);
    });
  }

  async function getProfileData(username: string) {
    const response = await api.get(`${links.api.social}/profile/username/${username}`);
    return response.data as ProfileData;
  }

  async function getMe() {
    const response = await api.get(`${links.api.schedule}/auth/me`);
    return await response.data;
  }

  useEffect(() => {
    getPermissions(isPerson, userInfo, cookieCompanyId).then(data => setMyPermissions(data));
    getMe()
      .then(data => {
        setMe(data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const company_selected = Cookies.get("companySelected");
    const userHasThisCompany =
      userInfo?.companies && company_selected
        ? userInfo?.companies.map((pos: any) => pos.id).includes(company_selected)
        : false;
    if (company_selected && userHasThisCompany) {
      setCompanySelected(company_selected);
    }
  }, []);

  return (
    <SocialContext.Provider
      value={{
        me,
        setMe,
        get about() {
          return about ? about : ({} as About);
        },
        setAbout,
        profiles,
        setProfiles,
        getProfileData,
        updateProfile,
        updateMe,
        publication,
        setPublication,
        recommendations,
        setRecommendations,
        suggestions,
        setSuggestions,
        experiences,
        setExperiences,
        address,
        setAddress,
        formations,
        setFormations,
        connections,
        setConnections,
        myPermissions,
        setMyPermissions,
        modulePermissions,
        setModulePermissions,
        companySelected,
        setCompanySelected,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
}

export function useSocialContext() {
  const context = useContext<SocialContextProps>(SocialContext);
  const {
    me,
    setMe,
    about,
    setAbout,
    profiles,
    getProfileData,
    updateProfile,
    updateMe,
    setProfiles,
    setPublication,
    publication,
    recommendations,
    setRecommendations,
    suggestions,
    setSuggestions,
    experiences,
    setExperiences,
    address,
    setAddress,
    formations,
    setFormations,
    connections,
    setConnections,
    myPermissions,
    setMyPermissions,
    modulePermissions,
    setModulePermissions,
    companySelected,
    setCompanySelected,
  } = context;
  return {
    me,
    setMe,
    about,
    setAbout,
    profiles,
    setProfiles,
    getProfileData,
    updateProfile,
    updateMe,
    setPublication,
    publication,
    recommendations,
    setRecommendations,
    suggestions,
    setSuggestions,
    experiences,
    setExperiences,
    address,
    setAddress,
    formations,
    setFormations,
    connections,
    setConnections,
    myPermissions,
    setMyPermissions,
    modulePermissions,
    setModulePermissions,
    companySelected,
    setCompanySelected,
  };
}
