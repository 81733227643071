import axios from "axios";
import { links } from "safira-app/config/links";

const baseURL = links.api.social;
const baseUrlCORE = links.api.core;
const api = axios.create();

export async function sendRequest(
  type: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  url: string,
  params?: string | any,
  apiName: "internal" | "external" = "internal",
  typeHeader: "application/json" | "multipart/form-data" = "application/json",
  onUploadProgress?: (progressEvent: any) => void,
) {
  const baseUrl = apiName === "external" ? baseUrlCORE : baseURL;

  try {
    switch (type) {
      case "GET":
        return await api.get(baseUrl + url);

      case "POST":
        return await api.post(baseUrl + url, params, {
          headers: {
            "Content-Type": `${typeHeader}`,
          },
          onUploadProgress,
        });

      case "PATCH":
        return await api.put(baseUrl + url, params, {
          headers: {
            "Content-Type": `${typeHeader}`,
          },
        });

      case "PUT":
        return await api.put(baseUrl + url, params, {
          headers: {
            "Content-Type": `${typeHeader}`,
          },
        });

      case "DELETE":
        return await api.delete(baseUrl + url, params);

      default:
      case "GET":
        return await api.get(baseUrl + url);
    }
  } catch (err) {
    console.error(err);
  }
}

export default api;
