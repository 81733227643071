import React, { createContext, useState } from "react";

export interface IProfile {
  avatar: string;
  share_my_publications: boolean;
  name: string;
  profile_id: string;
  type: string;
  username: string;
}

export interface IMeta {
  winners: any;
}

export interface IGuests {
  answered1: boolean;
  id: string;
  person_id: string;
  profile: IProfile;
}

export interface IEventList {
  canceled: boolean;
  created_at: string;
  deleted_at: string;
  description: string;
  end_date: string;
  event_name: string;
  guests: IGuests[];
  icon: string;
  id: string;
  isOver: boolean;
  is_notified: boolean;
  meta: IMeta;
  notify_one_day: boolean;
  notify_three_days: boolean;
  number_of_nominations: number;
  owner_id: string;
  start_date: string;
  status: number;
  updated_at: string;
}

export interface FeedbackContextData {
  eventsList: IEventList[];
  setEventsList: any;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  nextPage: string | null;
  setNextPage: React.Dispatch<React.SetStateAction<string | null>>;
}

export const FeedbackContext = createContext<FeedbackContextData>({} as FeedbackContextData);

export const FeedbackProviderContext: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [eventsList, setEventsList] = useState([]);
  const [filter, setFilter] = useState("opened");
  const [nextPage, setNextPage] = useState<string | null>(null);

  const context = {
    eventsList,
    setEventsList,
    filter,
    setFilter,
    nextPage,
    setNextPage,
  };

  return <FeedbackContext.Provider value={context}>{children}</FeedbackContext.Provider>;
};
