import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0; /* This is bad for acessibility! */
  }

  .react-toast-notifications__container
  {
    z-index: 9999;
  }

  .withCustomScrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .withCustomScrollbar::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 5px;
    width: 5px;
    height: 5px;
  }

  html {
    /* Percentage of user's browser font-size setting */
    /* 10px / 16px = 0.625 = 62.5% */
    /* font-size: 10px; */
    font-size: 62.5%; /* 0.5rem = 5px; 1rem = 10px; 1.5rem = 15px; 10rem = 100px;  */


    /* 1072px / 16px = 67em */
    @media (max-width: 67em) {
    /* 9px / 16px = 0.5625 = 56.25% */
    /* font-size: 9px; */
      font-size: 56.25%;
    }

    /* 690px / 16px = 43.125em */
    @media (max-width: 43.125em) {
    /* 8px / 16px = 0.5 = 50% */
    /* font-size: 8px; */
      font-size: 50%;
    }

    /* 400px / 16px = 43.125em */
    @media (max-width: 25em) {
    /* 7px / 16px = 0.5 = 50% */
    /* font-size: 7px; */
      font-size: 43.75%;
    }
  }

  body {
  background-color: #F2F3F5;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: 'Roboto', serif;
  }

  button:disabled {
    cursor: not-allowed;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    color: black;
  }

  /* button {
    cursor: pointer;
    color:#fff;
    font-size:1.6rem;
    font-weight: bold;
    width: 100%;
    height: 3.8rem;
    min-height: 3.8rem;
    padding: 0rem 1.5rem;
    border-radius: 100px;
    align-items: center;
    border: none;
    justify-content: center;
    background-color: #00568B;
    transition: all 400ms;
  } */

  /* button:hover{
    background-color: #006eb3;
  } */

  .button{
    width: 100%;
    color: #fff;
    height: 3.5rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 6px;
    background-color: #00558E;
    cursor: pointer;
  }

  .button:hover{
    background-color: #0063A5;
  }

  .button-o{
    width: 100%;
    color: #00558E;
    height: 3.5rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 6px;
    border: 1px solid #00558E;
    background-color: #fff;
    cursor: pointer;
    white-space: nowrap;
  }

  .button-o:hover{
    background-color: #f9f9f9;
  }

  .button-light{
    color: #fff;
    width: 100%;
    height: 3.5rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 6px;
    background-color: #019CC4;
    white-space: nowrap;
    cursor: pointer;
  }

  .button-light:hover{
    background-color: #01B7E7;
  }

  .button-light-o{
    color: #019CC4;
    height: 3.5rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 6px;
    background: transparent;
    border: 2px solid #019CC4;
    white-space: nowrap;
    cursor: pointer;
  }

  .button-light-o:hover{
    background-color: #e9e9e9;
  }

  .button1{
    display: flex;
    font-weight: 400;
    background: transparent;
    border: 1px solid #0485d6;
    color: #0485d6;
    border-radius: 4px;
    white-space: nowrap;
    align-items: center;
  }

  .button1:hover{
    border: 1px solid #444;
    color: #444;
  }

  .button2 {
    display: flex !important;
    min-width: 16rem;
    border: none !important;
    box-shadow: none !important;
    height: 3.5rem !important;
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    background: transparent !important;
    border: 1px solid #1eb2cb !important;
    color: #1eb2cb !important;
    border-radius: 4px !important;
    white-space: nowrap !important;
    align-items: center !important;
    justify-content: center;
  }

  .button2:hover {
    border: 1px solid #0485d6 !important;
    color: #0485d6 !important;
  }

  .button3 {
    display: flex !important;
    min-width: 16rem;
    height: 3.5rem !important;
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    background: #1eb2cb !important;
    color: #fff !important;
    border-radius: 4px !important;
    white-space: nowrap !important;
    align-items: center !important;
    justify-content: center;
  }

  .button3:hover {
    filter: brightness(0.85) !important;
  }

  .button3:disabled {
    filter: brightness(0.85);
    cursor: not-allowed
  }

  .cursorPointer{
    cursor: pointer;
  }

  .unAuthorized{
    background-color: #999999;
  }

  .unAuthorized:hover{
    background-color: #cccccc;
  }

  .unAuthorizedIcon{
    color: #999999;
  }

  .unAuthorizedIcon:hover{
    color: #cccccc;
  }

  input {
    color: #333;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 5px;
    border: 2px solid #e9e9e9;
  }

  input::placeholder{
    color:#d9d9d9;
  }

  input:focus{
    background:#e6f5ff;
  }

  .icon-help-lg {
    display: flex;
    color: #00558e;
    max-height: max-content;
    font-size: 3rem;
    border: 0;
    background: none;
  }

  .icon-help-medium {
    display: flex;
    color: #00558e;
    max-height: max-content;
    font-size: 2.5rem;
    border: 0;
    background: none;
  }
  .icon-help-small {
    display: flex;
    color: #00558e;
    max-height: max-content;
    font-size: 2rem;
    border: 0;
    background: none;
  }
  .content-btn-help {
    position: relative;
  }
  .content-btn-help:hover .tooltip-btn-help {
    display: flex;
    min-width: 30rem;
  }
  .tooltip-btn-help {
    display: none;
    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0 0 8px #d9d9d9;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 3.5rem;
    right: 0;
    z-index: 10;
  }

 .avatar-small{
  max-width: 4rem;
  max-height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  padding: .2rem;
  border-radius: 50%;
  background:#fff;
  border: 2px solid #007AAB;
}
 .avatar-medium{
  max-width: 6rem;
  max-height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  padding: .2rem;
  border-radius: 50%;
  background:#fff;
  border: 2px solid #007AAB;
}
 .avatar-large{
  max-width: 8rem;
  max-height: 8rem;
  min-width: 8rem;
  min-height: 8rem;
  padding: .2rem;
  border-radius: 50%;
  background:#fff;
  border: 2px solid #007AAB;
}
.no-recommendation span{
  display: block;
  text-align: center;
  color: #d8d8d8;
  font-weight: 600;
  font-size: 1.5rem;
}

/* MARGIN */
.m5{
  margin: .5rem;
}
.m10{
  margin: 1rem;
}
.m15{
  margin: 1.5rem;
}
.m20{
  margin: 2rem;
}
.m25{
  margin: 2.5rem;
}
.m30{
  margin: 3rem;
}
.m35{
  margin: 3.5rem;
}
.m40{
  margin: 4rem;
}
.m45{
  margin: 4.5rem;
}
.m50{
  margin: 5rem;
}
.m55{
  margin: 5.5rem;
}
.m60{
  margin: 6rem;
}

/* top */
.mT5{
  margin-top: .5rem;
}
.mT10{
  margin-top: 1rem;
}
.mT15{
  margin-top: 1.5rem;
}
.mT20{
  margin-top: 2rem;
}
.mT25{
  margin-top: 2.5rem;
}
.mT30{
  margin-top: 3rem;
}
.mT35{
  margin-top: 3.5rem;
}
.mT40{
  margin-top: 4rem;
}
.mT45{
  margin-top: 4.5rem;
}
.mT50{
  margin-top: 5rem;
}
.mT55{
  margin-top: 5.5rem;
}
.mT60{
  margin-top: 6rem;
}

/* right */
.mR5{
  margin-right: .5rem;
}
.mR10{
  margin-right: 1rem;
}
.mR15{
  margin-right: 1.5rem;
}
.mR20{
  margin-right: 2rem;
}
.mR25{
  margin-right: 2.5rem;
}
.mR30{
  margin-right: 3rem;
}
.mR35{
  margin-right: 3.5rem;
}
.mR40{
  margin-right: 4rem;
}
.mR45{
  margin-right: 4.5rem;
}
.mR50{
  margin-right: 5rem;
}
.mR55{
  margin-right: 5.5rem;
}
.mR60{
  margin-right: 6rem;
}

/* bottom */
.mB5{
  margin-bottom: .5rem;
}
.mB10{
  margin-bottom: 1rem;
}
.mB15{
  margin-bottom: 1.5rem;
}
.mB20{
  margin-bottom: 2rem;
}
.mB25{
  margin-bottom: 2.5rem;
}
.mB30{
  margin-bottom: 3rem;
}
.mB35{
  margin-bottom: 3.5rem;
}
.mB40{
  margin-bottom: 4rem;
}
.mB45{
  margin-bottom: 4.5rem;
}
.mB50{
  margin-bottom: 5rem;
}
.mB55{
  margin-bottom: 5.5rem;
}
.mB60{
  margin-bottom: 6rem;
}

/* left */
.mL5{
  margin-left: .5rem;
}
.mL10{
  margin-left: 1rem;
}
.mL15{
  margin-left: 1.5rem;
}
.mL20{
  margin-left: 2rem;
}
.mL25{
  margin-left: 2.5rem;
}
.mL30{
  margin-left: 3rem;
}
.mL35{
  margin-left: 3.5rem;
}
.mL40{
  margin-left: 4rem;
}
.mL45{
  margin-left: 4.5rem;
}
.mL50{
  margin-left: 5rem;
}
.mL55{
  margin-left: 5.5rem;
}
.mL60{
  margin-left: 6rem;
}


/* PADDING */
.p5{
  padding: .5rem;
}
.p10{
  padding: 1rem;
}
.p15{
  padding: 1.5rem;
}
.p20{
  padding: 2rem;
}
.p25{
  padding: 2.5rem;
}
.p30{
  padding: 3rem;
}
.p35{
  padding: 3.5rem;
}
.p40{
  padding: 4rem;
}
.p45{
  padding: 4.5rem;
}
.p50{
  padding: 5rem;
}
.p55{
  padding: 5.5rem;
}
.p60{
  padding: 6rem;
}

/* top */
.pT5{
  padding-top: .5rem;
}
.pT10{
  padding-top: 1rem;
}
.pT15{
  padding-top: 1.5rem;
}
.pT20{
  padding-top: 2rem;
}
.pT25{
  padding-top: 2.5rem;
}
.pT30{
  padding-top: 3rem;
}
.pT35{
  padding-top: 3.5rem;
}
.pT40{
  padding-top: 4rem;
}
.pT45{
  padding-top: 4.5rem;
}
.pT50{
  padding-top: 5rem;
}
.pT55{
  padding-top: 5.5rem;
}
.pT60{
  padding-top: 6rem;
}

/* right */
.pR5{
  padding-right: .5rem;
}
.pR10{
  padding-right: 1rem;
}
.pR15{
  padding-right: 1.5rem;
}
.pR20{
  padding-right: 2rem;
}
.pR25{
  padding-right: 2.5rem;
}
.pR30{
  padding-right: 3rem;
}
.pR35{
  padding-right: 3.5rem;
}
.pR40{
  padding-right: 4rem;
}
.pR45{
  padding-right: 4.5rem;
}
.pR50{
  padding-right: 5rem;
}
.pR55{
  padding-right: 5.5rem;
}
.pR60{
  padding-right: 6rem;
}

/* bottom */
.pB5{
  padding-bottom: .5rem;
}
.pB10{
  padding-bottom: 1rem;
}
.pB15{
  padding-bottom: 1.5rem;
}
.pB20{
  padding-bottom: 2rem;
}
.pB25{
  padding-bottom: 2.5rem;
}
.pB30{
  padding-bottom: 3rem;
}
.pB35{
  padding-bottom: 3.5rem;
}
.pB40{
  padding-bottom: 4rem;
}
.pB45{
  padding-bottom: 4.5rem;
}
.pB50{
  padding-bottom: 5rem;
}
.pB55{
  padding-bottom: 5.5rem;
}
.pB60{
  padding-bottom: 6rem;
}

/* left */
.pL5{
  padding-left: .5rem;
}
.pL10{
  padding-left: 1rem;
}
.pL15{
  padding-left: 1.5rem;
}
.pL20{
  padding-left: 2rem;
}
.pL25{
  padding-left: 2.5rem;
}
.pL30{
  padding-left: 3rem;
}
.pL35{
  padding-left: 3.5rem;
}
.pL40{
  padding-left: 4rem;
}
.pL45{
  padding-left: 4.5rem;
}
.pL50{
  padding-left: 5rem;
}
.pL55{
  padding-left: 5.5rem;
}
.pL60{
  padding-left: 6rem;
}

/* TABLE */
/* table{
  width: 100%;
  text-align: center;
  border: none;
  margin-top:2rem;
  border-spacing: 0;
}
table thead tr td{
  background-color: #00568B
}
table thead tr td:first-child{
  border-top-left-radius: 10px;
}
table thead tr td:last-child{
  border-top-right-radius: 10px;
}
table thead{
  width: 100%;
}
table tr td{
  border-left: 1px solid #ABD7E8;
}
table tr td:first-child{
  border-left: none;
}
table thead tr{
  height: 4rem;
  color: #fff;
  font-size: 1.5rem;
}
table tr td{
  padding-left: 1rem;
}
table tbody tr{
  height: 30px;
  color: #c4c4c4;
  font-size: 1.5rem;
  background-color: #EEF4F7;
}
table tbody tr:nth-child(odd){
  background-color: #D6E8F0;
}
table tfoot tr td{
  height: 4rem;
  background-color: #00568B;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
}*/
`;

// ROWS

export const RowTopLeft = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const RowTopRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const RowTopCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: center;
`;

export const RowTopBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: space-between;
`;

export const RowBottomLeft = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const RowBottomRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const RowBottomCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: center;
`;

export const RowBottomBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RowCenterLeft = styled.div`
  display: flex;
  height: max-content;
  align-items: center;
  justify-content: flex-start;
`;

export const RowCenterRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-end;
`;

export const RowCenterCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const RowCenterBetween = styled.div`
  display: flex;
  width: 100%;
  height: 3.5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const RowCenterBetweenWrap = styled.div`
  display: flex;
  flex: 1;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// COLUMNS
export const ColumnLeftTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ColumnLeftCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnLeftBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ColumnLeftBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const ColumnCenterTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ColumnCenterCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnCenterBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ColumnCenterBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const ColumnRightTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ColumnRightCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnRightBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ColumnRightBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
`;

interface CustomDiv {
  value: string;
}

export const Border = styled.div`
  padding: 2rem;
  border-radius: 6px;
  border: 1px solid #00568b;
`;

export const Label = styled.label`
  color: #444;
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
`;

export const Width = styled.div<CustomDiv>`
  width: ${props => props.value};
`;

export const MinWidth = styled.div<CustomDiv>`
  min-width: ${props => props.value};
`;

export const MaxWidth = styled.div<CustomDiv>`
  width: ${props => props.value};
`;

export const Height = styled.div<CustomDiv>`
  width: ${props => props.value};
`;

export const Margin = styled.div<CustomDiv>`
  margin: ${props => props.value};
`;

export const MarginStretch = styled.div<CustomDiv>`
  width: 100%;
  margin: ${props => props.value};
`;

export const Padding = styled.div<CustomDiv>`
  margin: ${props => props.value};
`;

export const PaddingStretch = styled.div<CustomDiv>`
  width: 100%;
  margin: ${props => props.value};
`;

export const Flex = styled.div<CustomDiv>`
  flex: ${props => props.value};
`;

export const ContentModal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;

  .scrool-modal {
    overflow: auto;
  }

  .icon {
    font-size: 1.6rem;
    color: #a9a9a9;
    cursor: pointer;
  }

  .icon2 {
    font-size: 2.5rem;
    color: #a9a9a9;
  }

  .avatar-modal {
    min-width: 8rem;
    min-height: 8rem;
    max-width: 8rem;
    max-height: 8rem;
    padding: 0.3rem;
    border-radius: 100%;
    background: #fff;
    border: 3px solid #02385d;
  }

  .title-modal {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 1rem;
  }

  .title-m-modal {
    color: #00558e;
    font-size: 1.6rem;
    font-weight: bold;
  }

  progress {
    -webkit-appearance: none;
    color: #00558e;
    min-width: 20rem;
    width: 100%;
    height: 1.5rem;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid #009dc4;

    &::-webkit-progress-bar {
      background: #d9d9d9;
    }

    &::-moz-progress-bar {
      background: #d9d9d9;
    }

    &::-webkit-progress-value {
      border-radius: 6px;
      background: #00558e;
    }

    &::-moz-progress-value {
      border-radius: 6px;
      background: #00558e;
    }
  }

  /* SELECT */
  .select {
    display: flex;
    color: #555;
    flex: 1;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0 0.5rem;
    min-width: 25rem;
    min-height: 3.5rem;
    border-radius: 5px;
    border: 1px solid #b9b9b9;
    align-items: center;
    position: relative;
    justify-content: flex-end;
  }

  .select select {
    flex: 1;
    margin: 0;
    margin-left: 1rem;
    width: calc(100% - 1rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    border: transparent;
    background: transparent;
  }

  .select .content-icon-select {
    display: flex;
    color: #fff;
    height: 100%;
    width: 4rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #00558e;
    align-items: center;
    justify-content: center;
  }

  .select .icon-select {
    color: #fff;
    cursor: pointer;
  }
  /* FIM SELECT */
`;

export const BodyModal = styled.div`
  display: flex;
  width: 50%;
  padding: 2rem;
  max-height: 97%;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  z-index: 6;

  h1 {
    color: #444;
    font-size: 1.8rem;
    font-weight: bold !important;
  }

  .icon {
    color: #444;
    font-size: 2.5rem;
  }

  label {
    color: #444;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
  }

  input[type="radio"] {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
  }

  hr {
    margin: 1rem 0;
    border: none;
    border-bottom: 1px solid #e9e9e9;
  }

  input {
    color: #333;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 5px;
    border: 2px solid #e9e9e9;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .clique-aqui {
    text-decoration: none;
    color: #fff;
    width: max-content;
    height: 2.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    background: #00558e;
    cursor: pointer;
    white-space: nowrap;
  }

  .error {
    font-size: 1.2rem;
    color: red;
  }
`;

export const BodyModalSmall = styled.div`
  display: flex;
  width: 35rem;
  padding: 2rem;
  height: max-content;
  background: #fff;
  border-radius: 1rem;
  flex-direction: column;
  z-index: 6;

  h1 {
    color: #444;
    font-size: 1.8rem;
    font-weight: bold !important;
  }

  .icon {
    color: #444;
    font-size: 2.5rem;
  }

  label {
    color: #444;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
  }

  input[type="radio"] {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
  }

  hr {
    margin: 1rem 0;
    border: none;
    border-bottom: 1px solid #e9e9e9;
  }

  input {
    color: #333;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 5px;
    border: 2px solid #e9e9e9;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .clique-aqui {
    text-decoration: none;
    color: #fff;
    width: max-content;
    height: 2.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    background: #00558e;
    cursor: pointer;
  }
`;

export const BodyModalLarge = styled.div`
  display: flex;
  width: 75%;
  max-width: 110rem;
  padding: 2rem 2.5rem;
  max-height: 90%;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  z-index: 6;
  font-family: "Open Sans", sans-serif;

  h1 {
    color: #444;
    font-size: 1.8rem;
    font-weight: bold !important;
  }

  .icon {
    color: #444;
    font-size: 2.5rem;
    cursor: pointer;
  }

  label {
    color: #444;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
  }

  input[type="radio"] {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
  }

  hr {
    margin: 1rem 0;
    border: none;
    border-bottom: 1px solid #e9e9e9;
  }

  input {
    color: #333;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 5px;
    border: 2px solid #e9e9e9;
  }

  /* button {
    background: none;
    border: none;
  } */
`;

export const Select = styled.div`
  display: flex;
  color: #333;
  width: 100%;

  height: 2.5rerm;
  border-radius: 5px;
  border: 2px solid #e9e9e9;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  background: #fff;

  select {
    flex: 1;
    margin: 0;
    margin-left: 1rem;
    width: calc(100% - 1rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    border: transparent;
    background: transparent;
  }

  .content-icon {
    display: flex;
    color: #fff;
    height: 100%;
    width: 4rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #00558e;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .icon {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
  }
  /* FIM SELECT */
`;

export const ScrollH = styled.div`
  overflow-x: auto;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 2rem;
  height: 2rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  div {
    display: flex;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    min-width: 4rem;
    height: 3.5rem;
    padding: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #00558e;
    align-items: center;
    justify-content: center;
  }

  input {
    color: #333;
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #e9e9e9;
    border-left: 0;
  }
`;

export const Hr = styled.hr`
  margin: 1rem 0;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
`;
