import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function LoadingComponent() {
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
